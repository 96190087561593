<template>
  <b-tabs
    ref="manage-category"
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >
    <!-- info -->
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Catalogo</span>
      </template>
      <Catalog />
    </b-tab>
    <b-tab
      lazy
      :disabled="apiLoaded"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Categorie</span>
      </template>
      <Categories
        :categories="categories"
      />
    </b-tab>
    <!--/ change password tab -->

    <!-- <b-tab lazy>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Documenti</span>
      </template>
      <CreateDocument />
    </b-tab> -->
    <!-- <b-tab lazy>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">DocumentEditor</span>
      </template>
      <DocumentEditor />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import axios from 'axios'
import Categories from './Categories.vue'
import Catalog from './Catalog.vue'
// import CreateDocument from './CreateDocument.vue'
// import DocumentEditor from './DocumentEditor.vue'

export default {
  components: {
    BTabs,
    BTab,
    Categories,
    Catalog,
    // CreateDocument,
    // DocumentEditor,
  },
  data() {
    return {
      apiLoaded: true,
      categories: [],
    }
  },
  computed: {

  },
  mounted() {
    this.loadCategories()
    this.loadCatalogs()
  },
  methods: {
    async loadCatalogs() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, [{}],
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Catalogo',
            metodo: 'getCataloghi',
            ACL: '',
          },
        }).then(res => {
        // console.log('cataloghi', res)
        if (res.data.esito === 'OK') {
          this.$store.dispatch('catalogs/setCatalogs', res.data.cataloghi)
        }
      })
        .catch(e => e)
    },
    async loadCategories() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, [{}],
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Categoria',
            metodo: 'getCategorie',
            ACL: '',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          this.categories = [...res.data.categorie.sort((a, b) => parseFloat(a.ordinamento) - parseFloat(b.ordinamento))]
          this.$store.dispatch('categories/setCategories', this.categories)
          this.apiLoaded = false
        }
      })
        .catch(e => e)
      this.categories = this.categories.map(el => ({ children: [], ...el }))
      // console.log(this.categories)
      this.categories = this.fromListToTreeView(this.categories)
      // console.log('this.categories', this.categories)
    },
    fromListToTreeView(list1) {
      const list = list1
      // console.log('list', list)
      const map = {}
      let node = []
      const roots = []
      let i = 0
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i
        list[i].children = []
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.idCategoriaPadre !== null) {
          // check if has parent -> ordina i figli in maniera crescente su ordinamento e pusha il nodo
          node.children = node.children.sort((a, b) => parseFloat(a.ordinamento) - parseFloat(b.ordinamento))
          // console.log(node)
          list[map[node.idCategoriaPadre]].children.push(node)
          // figli ordinati in maniera crescente su ordinamento
          list[map[node.idCategoriaPadre]].children = list[map[node.idCategoriaPadre]].children.sort((a, b) => parseFloat(a.ordinamento) - parseFloat(b.ordinamento))
        } else {
          node.children = node.children.sort((a, b) => parseFloat(b.ordinamento) - parseFloat(a.ordinamento))
          // console.log('nodeee', node)
          roots.push(node)
        }
      }
      return roots
    },
  },
}
</script>
<style scoped>
  .cardPointer{
    position: relative;
    top: 0;
    transition: top ease 0.5s;
  }
  .cardPointer:hover{
    cursor: pointer;
    top: -10px;

  }
</style>

/* eslint-disable */
export const findNode = (id, array) => {
    for (const node of array) {
        if (node.id === id) return node;
        if (node.children) {
        const child = findNode(id, node.children);
        if (child) return child;
        }
    }
}
export const swap = (obj1, key1, obj2, key2) => {
    [obj1[key1], obj2[key2]] = [obj2[key2], obj1[key1]]
}

<script>
/* eslint-disable */
</script>
<template>
  <b-card>
    {{ categories }}
    <hr>
    <b-row>
      <!-- data1 {{ fields }} -->
      <b-col
        cols="4"
      >
        <div class="col-lg-12 control-section tree-default">
          <div
            class="tree-control-wrapper"
          >
            <ejs-treeview
              id="treeview"
              ref="treeview"
              :fields="fields"
              :allow-drag-and-drop="true"
              :allow-multi-selection="true"
              :allow-editing="true"
              :node-dropped="dataSourceChanged"
              :node-drag-stop="dataSourceChanged2"
            /><!-- :data-source-changed="dataSourceChanged2" -->
          </div>
        </div>
      </b-col>
      <b-col
        cols="8"
      >
        <b-col
          cols="12"
          class="mb-2"
        >
          <!-- media -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  :src="img"
                  height="80"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="ml-75">
              <!-- upload button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
              >
                Upload
              </b-button>
              <b-form-file
                ref="refInputEl"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @input="changeImg"
              />
              <!--/ upload button -->

              <!-- reset -->
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
              >
                Reset
              </b-button> -->
              <!--/ reset -->
              <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
            </b-media-body>
          </b-media>
          <!--/ media -->
        </b-col>
        <b-col
          md="12"
        >

          <h5>Nome</h5>
          <b-form-input
            id="input-name-category"
            v-model="selectedCategory.label"
            placeholder="ex. Mangimi, Farmaci.."
          />

        </b-col>
        <b-col
          md="12"
        >
          <b-form-group
            label="Cataloghi"
            label-for="category-catalogs"
          >
            <v-select
              id="category-catalogs"
              v-model="optionCat"
              :options="optionCat"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              placeholder="Add Options"
              label="title"
            />

          </b-form-group>
        </b-col>
        <b-col
          md="12"
        >
          <h5>Descrizione</h5>
          <b-form-textarea
            id="textarea-description-category"
            v-model="selectedCategory.description"
            placeholder="Textarea"
            rows="2"
          />
        </b-col>
        <b-col class="d-flex justify-content-end mt-1">
          <b-button variant="primary">
            Cambia
          </b-button>
        </b-col>
        <b-col
          md="12"
        >
          <b-card-actions
            title="Prodotti e Servizi Categoria"
            action-collapse
            class="leavePadding"
          >
            <ProductsServicesTable />
          </b-card-actions>
        </b-col>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Vue from 'vue'
import {
  BRow, BCol, BCard, BFormTextarea, BFormFile, BMedia, BMediaAside, BImg, BLink, BButton, BMediaBody, BCardText, BFormInput, BFormGroup, VBToggle,
} from 'bootstrap-vue'
import { TreeViewPlugin } from '@syncfusion/ej2-vue-navigations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
import { findNode, swap } from '@core/utils/nestedObject'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ProductsServicesTable from './products-services-table/ProductsServicesTable.vue'
// import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

Vue.use(TreeViewPlugin)

const data1 = [
  {
    id: '01',
    name: 'Local Disk (C:)',
    expanded: true,
    subChild: [
      {
        id: '01-01',
        name: 'Program Files',
        subChild: [
          { id: '01-01-01', name: 'Windows NT' },
          { id: '01-01-02', name: 'Windows Mail' },
          { id: '01-01-03', name: 'Windows Photo Viewer' },
        ],
      },
      {
        id: '01-02',
        name: 'Users',
        expanded: true,
        subChild: [
          { id: '01-02-01', name: 'Smith' },
          { id: '01-02-02', name: 'Public' },
          { id: '01-02-03', name: 'Admin' },
        ],
      },
      {
        id: '01-03',
        name: 'Windows',
        subChild: [
          { id: '01-03-01', name: 'Boot' },
          { id: '01-03-02', name: 'FileManager' },
          { id: '01-03-03', name: 'System32' },
        ],
      },
    ],
  },
  {
    id: '02',
    name: 'Local Disk (D:)',
    subChild: [
      {
        id: '02-01',
        name: 'Personals',
        subChild: [
          { id: '02-01-01', name: 'My photo.png' },
          { id: '02-01-02', name: 'Rental document.docx' },
          { id: '02-01-03', name: 'Payslip.pdf' },
        ],
      },
      {
        id: '02-02',
        name: 'Projects',
        subChild: [
          { id: '02-02-01', name: 'Blazor Application' },
          { id: '02-02-02', name: 'TypeScript Application' },
          { id: '02-02-03', name: 'React Application' },
        ],
      },
      {
        id: '02-03',
        name: 'Office',
        subChild: [
          { id: '02-03-01', name: 'Work details.docx' },
          { id: '02-03-02', name: 'Weekly report.docx' },
          { id: '02-03-03', name: 'Wishlist.csv' },
        ],
      },
    ],
  },
  {
    id: '03',
    name: 'Local Disk (E:)',
    icon: 'folder',
    subChild: [
      {
        id: '03-01',
        name: 'Pictures',
        subChild: [
          { id: '03-01-01', name: 'Wind.jpg' },
          { id: '03-01-02', name: 'Stone.jpg' },
          { id: '03-01-03', name: 'Home.jpg' },
        ],
      },
      {
        id: '03-02',
        name: 'Documents',
        subChild: [
          { id: '03-02-01', name: 'Environment Pollution.docx' },
          { id: '03-02-02', name: 'Global Warming.ppt' },
          { id: '03-02-03', name: 'Social Network.pdf' },
        ],
      },
      {
        id: '03-03',
        name: 'Study Materials',
        subChild: [
          { id: '03-03-01', name: 'UI-Guide.pdf' },
          { id: '03-03-02', name: 'Tutorials.zip' },
          { id: '03-03-03', name: 'TypeScript.7z' },
        ],
      },
    ],
  },
]

export default Vue.extend({
  components: {
    BRow,
    BCol,
    // AppCollapseItem,
    // AppCollapse,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    BFormFile,
    BMedia,
    BMediaAside,
    BImg,
    BButton,
    BLink,
    BMediaBody,
    BCardText,
    BFormInput,
    BCardActions,
    ProductsServicesTable,
    vSelect,
    BFormGroup,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        dataSource: this.categories, id: 'id', text: 'nome', child: 'children',
        // dataSource: this.categories, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild',
      },
      // img default
      // eslint-disable-next-line global-require
      img: 'https://fakeimg.pl/50/',
      selectedCategory: {
        label: 'Level three 1',
        id: 2,
        description: 'sono descrizione1',
      },
      data: [{
        label: 'Tutte le categorie',
        id: 1,
        children: [
          {
            label: 'Level three 1',
            id: 2,
            description: 'sono descrizione1',
            children: [{ label: 'level three 1.1', id: 6, description: 'sono descrizione1.1' }],
          },
          { label: 'Level three 2', id: 3, description: 'sono descrizione2' },
          { label: 'Level three 3', id: 4, description: 'sono descrizione3' },
          { label: 'Level three 4', id: 5, description: 'sono descrizione4' },
        ],
      }],
      optionCat: ['Generali', 'Mangimi', 'Consumabili'],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      content_visible: !this.collapsed,
      collapsed: false,

    }
  },
  created() {
  },
  methods: {
    dataSourceChanged(args) {
      // console.log('node dropped', args)
      const position = {
        type: args.position,
        id: args.droppedNodeData.id,
        index: args.dropIndex,
      }
      const tree = this.$refs.treeview.getTreeData()
      let categoryStart = {}
      let categoryEnd = {}

      // caso 1: il valore categoriaPadreID è null
      if (args.dropLevel === 1 && !categoryEnd.id) {
        categoryStart = findNode(Number(args.draggedNodeData.id), tree)
        categoryStart.idCategoriaPadre = null
        // ricalcolo index
        const categoriesFathers = tree.map((cat, index) => {
          cat.ordinamento = index
          return cat
        })
        // aggiunta default catalogo
        const categoriesFathers1 = categoriesFathers.map(el => ({ cataloghi: [{ id: 1 }], ...el }))
        // setCategoria funziona
        this.setCategories(categoriesFathers1)
      } else if (args.droppedNodeData.hasChildren) {
        // console.log('ha childrens', )
        categoryEnd = findNode(Number(args.droppedNodeData.id), tree)
        categoryStart = findNode(Number(args.draggedNodeData.id), tree)
        categoryStart.idCategoriaPadre = categoryEnd.id
        const categoriesFathers = categoryEnd.children.map((cat, index) => {
          cat.ordinamento = index
          return cat
        })
        const categoriesFathers1 = categoriesFathers.map(el => ({ cataloghi: [{ id: 1 }], ...el }))
        // console.log(categoriesFathers)
        // console.log('category End node', categoryEnd)
        // setCategoria funziona
        this.setCategories(categoriesFathers1)
      } else if (!args.droppedNodeData.hasChildren) {
        // console.log('non ha children')
        categoryEnd = findNode(Number(args.droppedNodeData.id), tree)
        categoryStart = findNode(Number(args.draggedNodeData.id), tree)
        categoryStart.idCategoriaPadre = categoryEnd.idCategoriaPadre
        const categoriaPadre = findNode(Number(categoryStart.idCategoriaPadre), tree)
        const categoriesFathers = categoriaPadre.children.map((cat, index) => {
          cat.ordinamento = index
          return cat
        })
        const categoriesFathers1 = categoriesFathers.map(el => ({ cataloghi: [{ id: 1 }], ...el }))
        // setCategoria funziona
        this.setCategories(categoriesFathers1)
      }
      /* console.log('PRIMA DI TUTTO categoryEnd', categoryEnd)
      console.log('PRIMA DI TUTTO categoryStart', categoryStart)
      console.log('position', position)
      */

      // [arr[0], arr[1]] = [arr[1], arr[0]]

    },
    async setCategories(categories) {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify(categories),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Categoria',
            metodo: 'setCategoria',
            ACL: '',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('ok')
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories)
    },
    dataSourceChanged2() {
      // console.log('drop stop', args)
    },
    onChangeTreeview() {
      // console.log(this.fields)
    },
    onSubmit() {
      // console.log(form)
    },
    changeImg() {},
    // all tree event
    showSelectedCategory(category) {
      // diverso id da 'TUTTE LE CATEGORIE'
      if (category.id !== 1) {
        // console.log(category.id)
        this.selectedCategory = { ...category }
        // console.log(this.selectedCategory)
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      // console.log('dropNode', dropNode)
      // console.log('draggingNode', draggingNode)
      // console.log('type', type)
      if (draggingNode && type && dropNode.data.id === 1) {
        return false
      }
      return true
    },
    allowDrag(draggingNode) {
      // console.log(this.data)
      return draggingNode.data.label.indexOf('Tutte le Categorie') === -1
    },
    addCategory(title) {
      this.$refs['sidebar-category-event-handler'].setTitle(title)
    },
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// scss resettato su _collapsw.scss dropdown box-shadow commentato
.myCard{
  background-color: transparent;
  box-shadow: initial;
  padding-left: 0;
  padding-top:0;
  padding-bottom:0;
}
.myCard > .card-body {
  margin-bottom: 0;
}

.el-tree {
  background: transparent;
}

.leavePadding .card-body {
  padding-left: 11px;
  padding-right: 11px;
}
 .control_wrapper {
        display: block;
        max-width: 350px;
        max-height: 350px;
        margin: auto;
        overflow: auto;
        border: 1px solid #dddddd;
        border-radius: 3px;
    }
</style>

<template>
  <div>
    <!-- input search -->
    <div class="">
      <b-row class="d-flex justify-content-between">
        <div class="d-flex flex-column flex-lg-row">
          <b-col>
            <b-form-radio
              v-model="selectType"
              class="m-1"
              name="some-radios"
              value=""
            >
              Tutti
            </b-form-radio>
            <b-form-radio
              v-model="selectType"
              class="m-1"
              name="some-radios"
              value="prodotto"
            >
              Prodotto
            </b-form-radio>
          </b-col>
          <b-col>
            <b-form-radio
              v-model="selectType"
              class="m-1"
              name="some-radios"
              value="servizio"
            >
              Servizio
            </b-form-radio>
          </b-col>
          <b-col>
            <b-form-radio
              v-model="selectType"
              class="m-1"
              name="some-radios"
              value="bundle"
            >
              Bundle
            </b-form-radio>
          </b-col>
        </div>
        <b-col
          cols="12"
          md="3"
          lg="3"
        >
          <b-form-group>
            <div>
              <label class="mr-1">Cerca</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Ricerca generale"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columnsFiltered"
      :rows="filterType"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      @on-row-click="showDetails"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','30']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!--    <template #code>
      {{ codeColumnSearch }}
    </template> -->
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BFormRadio, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BContainer,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadio,
    BCol,
    BRow,
  },
  data() {
    return {
      selected: [],
      selectType: '',
      pageLength: 10,
      dir: false,
      // codeColumnSearch,
      columns: [

        {
          label: 'ID',
          field: 'id',
          width: '90px',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca id',
          },
        },
        {
          label: 'Nome',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca Nome',
          },
        },
        {
          label: 'Tipo',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca Tipo',
          },
        },
        {
          label: 'Stato',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca Stato',
          },
        },
        {
          label: 'Prezzo',
          field: 'price',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca prezzo',
          },
        },
      ],
      columnsFiltered: [],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        Active       : 'light-success',
        Inactive     : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    filterType() {
      if (this.selectType !== '') {
        return this.rows.filter(r => r.type.toLowerCase() === this.selectType.toLowerCase())
      }
      return this.rows
    },
  },
  watch: {},
  /* this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data }) */
  created() {
    this.columnsFiltered = this.columns

    // console.log('Sono su Created di ListRoles')
    this.rows = [
      {
        id: '1',
        // avatar: require('@/assets/images/avatars/10-small.png'),
        fullName: 'Mangimi',
        type: 'Prodotto',
        codArt: '0008',
        status: 'Active',
        price: '10',
      },
      {
        id: '2',
        // avatar: require('@/assets/images/avatars/1-small.png'),
        fullName: 'Farmaci',
        type: 'Prodotto',
        codArt: '0009',
        status: 'Active',
        price: '15',
      },
      {
        id: '3',
        // avatar: require('@/assets/images/avatars/9-small.png'),
        fullName: 'Parafarmaci',
        type: 'Servizio',
        codArt: '0011',
        status: 'Inactive',
        price: '7',
      },
      {
        id: '4',
        // avatar: require('@/assets/images/avatars/3-small.png'),
        fullName: 'Dorolice Crossman',
        type: 'Bundle',
        codArt: '0015',
        status: 'Active',
        price: '12',
      },
    ]
  },
  methods: {
    showDetails(params) {
      if (params.row.type === 'Servizio') {
        this.$router.push({ name: 'details-services', params: { id: params.row.id } })
      } else if (params.row.type === 'Prodotto') {
        this.$router.push({ name: 'details-products', params: { id: params.row.id } })
      }
    },
    filterColumns(columns) {
      columns.push('action')
      // console.log(columns)
      this.columnsFiltered = this.columns.filter(col => columns.includes(col.field))
    },
  },
}
</script>
<style scoped>
</style>

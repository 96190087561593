<template>
  <b-sidebar
    id="sidebar-catalog-event-handler"
    v-model="show"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    backdrop-variant="dark"
    backdrop
    :no-close-on-backdrop="true"
    shadow
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h6>{{ title }}</h6>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide()"
          />
        </div>
      </div>
      <b-row class="m-1">
        <b-col cols="6">
          <b-form-group
            label="Nome"
            label-for="name-warehouse"
            label-class="font-weight-bolder"
          >
            <b-form-input
              id="name-warehouse"
              placeholder="magazzino 1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="m-1">
        <b-col cols="12">
          <b-form-group
            label="Descrizione"
            label-for="description-warehouse"
            label-class="font-weight-bolder"
          >
            <b-form-textarea
              id="description-warehouse"
              placeholder="2"
              rows="2"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      title: '',
      groupsPrices: ['Gruppo1', 'Gruppo2', 'Gruppo3', 'Gruppo4'],
      specialPricesToStart: '2021/12/20',
      specialPricesToEnd: '2021/12/29',
      configFlatPicker: {
        static: false,
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'M/j/Y',
        altInput: true,
        dateFormat: 'Y/m/d',
        locale: Italian, // locale for this instance only
      },
    }
  },
  methods: {
    setTitle(title) {
      this.title = title
    },
    openSidebar() {
      this.show = true
    },
    closeSidebar() {
      this.show = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
